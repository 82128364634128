@use '@/styles/utils/mixins.scss' as *;

.riskInitiativeWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  .riskInitiativeForm {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    max-width: 52.5rem;
  }
  .riskInitiativeFormContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .riskInitiativeFormField {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .float-label {
        .as-label,
        .ant-select-selector,
        .float-input {
          background: $neutral01 !important;
        }
      }
    }
  }
}

.formDivider {
  border-color: $primaryAlt !important;
  width: 100%;
  margin: 0 !important;
}
